import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "./Swiper.css";


const SwiperDeals = ({ img1, img2, barName }) => {
  return (
    <Swiper
      modules={[Pagination]}
      slidesPerView={1}
      pagination={{ clickable: true }}
      className="deal__swiper mob"
    >
      <SwiperSlide className="slide">
        <img
          src={img1}
          alt={barName}
          title={barName}
          width="293"
          height="217"
          className="bar__img"
        />
      </SwiperSlide>
      <SwiperSlide className="slide">
        <img
          src={img2}
          alt={barName}
          title={barName}
          width="293"
          height="217"
          className="bar__img"
        />
      </SwiperSlide>
    </Swiper>
  );
};

export default SwiperDeals;
