import React from "react";
import "./Footer.css";
import age from "../../images/age.svg";
import telegram from "../../images/telegram.svg";
import inst from "../../images/inst.svg";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer id="contacts" className="footer">
      <div className="footer__top-container">
        <p className="t-18 footer__el1">
          No ganarás dinero real con nosotros, todas las actividades son sólo
          para fines de entretenimiento.
        </p>
        <p className="t-18 footer__el2">
          Si desea ponerse en contacto con nosotros o dejarnos algún comentario,
          ¡nos encantará saber de usted! También puede ponerse en contacto con
          nosotros por correo electrónico:
        </p>
        <a
          href="mailto:1xbetibet@gmail.com"
          className="footer__el3 footer__link t-18"
          target="_blank"
          rel="noreferrer"
        >
          1xbetibet@gmail.com
        </a>
        <p className="t-18 footer__el4">
          «1xbetibet.com» - Utiliza cookies para ofrecerte la mejor experiencia.
          Si continúa navegando, consideramos que acepta su uso. Para más
          información
        </p>
        <Link to="/cookies" className="footer__link footer__el5 t-18">
          Más información
        </Link>
        <Link to="/privacy" className="footer__link footer__el6 t-18">
          Política de privacidad
        </Link>
      </div>

      <div className="footer__link-container footer__bot-container">
        <div className="footer__social">
          <a href="#">
            <img src={inst} alt="instagram icon" width="32" height="32"/>
          </a>
          <a href="#">
            <img src={telegram} alt="telegram icon" width="32" height="32"/>
          </a>
        </div>
        <img src={age} alt="age limit 18+" className="footer__el" width="51" height="51"/>
      </div>
    </footer>
  );
};

export default Footer;
