import React from "react";

const Privacy = () => {
  return (
    <main className="privacy">
      <h2>Política de privacidad de 1xbetibet.com</h2>
      <p className="t-18 ">
        En 1xbetibet.com, accesible desde 1xbetibet.com, una de nuestras
        principales prioridades es la privacidad de nuestros visitantes. Este
        documento de política de privacidad contiene tipos de información que
        1xbetibet.com recopila y registra y cómo la utilizamos.
      </p>
      <p className="t-18 ">
        Si tiene preguntas adicionales o necesita más información sobre nuestra
        política de privacidad, no dude en ponerse en contacto con nosotros.
      </p>
      <h3>Archivos de registro</h3>
      <p className="t-18 ">
        1xbetibet.com sigue un procedimiento estándar de uso de archivos de
        registro. Estos archivos registran a los visitantes cuando visitan
        sitios web. Todas las empresas de alojamiento hacen esto y forman parte
        del análisis de los servicios de alojamiento. La información recopilada
        por los archivos de registro incluye direcciones de Protocolo de
        Internet (IP), tipo de navegador, Proveedor de Servicios de Internet
        (ISP), fecha y marca de tiempo, páginas de referencia/salida y,
        posiblemente, la cantidad de clics. Estos no están vinculados a ninguna
        información que sea personalmente identificable. El propósito de la
        información es analizar tendencias, administrar el sitio, rastrear el
        movimiento de los usuarios en el sitio y recopilar información
        demográfica. Nuestra política de privacidad fue creada con la ayuda del
        Generador de Políticas de Privacidad.
      </p>
      <h3>Nuestros socios publicitarios</h3>
      <p className="t-18 ">
        Algunos de los anunciantes en nuestro sitio web pueden usar cookies y
        balizas web. Nuestros socios publicitarios están enumerados a
        continuación. Cada uno de nuestros socios publicitarios tiene su propia
        política de privacidad para sus políticas sobre datos de usuario. Para
        un acceso más fácil, hemos vinculado sus políticas de privacidad a
        continuación.
      </p>
      <h3>Políticas de privacidad</h3>
      <p className="t-18 ">
        Puede consultar esta lista para encontrar la política de privacidad de
        cada uno de los socios publicitarios de 1xbetibet.com.
      </p>
      <p className="t-18 ">
        Los servidores de anuncios o las redes publicitarias de terceros
        utilizan tecnologías como cookies, JavaScript o balizas web que se
        utilizan en sus respectivos anuncios y enlaces que aparecen en
        1xbetibet.com, que se envían directamente al navegador de los usuarios.
        Reciben automáticamente su dirección IP cuando esto ocurre. Estas
        tecnologías se utilizan para medir la efectividad de sus campañas
        publicitarias y/o para personalizar el contenido publicitario que ve en
        los sitios web que visita.
      </p>
      <p className="t-18 ">
        Tenga en cuenta que 1xbetibet.com no tiene acceso ni control sobre estas
        cookies que utilizan los anunciantes de terceros.
      </p>
      <h3>Políticas de privacidad de terceros</h3>
      <p className="t-18 ">
        La política de privacidad de 1xbetibet.com no se aplica a otros
        anunciantes o sitios web. Por lo tanto, le recomendamos que consulte las
        respectivas políticas de privacidad de estos servidores de anuncios de
        terceros para obtener información más detallada. Puede incluir sus
        prácticas e instrucciones sobre cómo optar por no participar en ciertas
        opciones.
      </p>
      <p className="t-18 ">
        Puede optar por desactivar las cookies a través de las opciones
        individuales de su navegador. Para obtener información más detallada
        sobre la gestión de cookies con navegadores web específicos, se puede
        encontrar en los respectivos sitios web de los navegadores. ¿Qué son las
        cookies?
      </p>
      <h3>Información para niños</h3>
      <p className="t-18 ">
        Otra parte de nuestra prioridad es agregar protección para los niños
        mientras usan Internet. Alentamos a los padres y tutores a observar,
        participar y/o monitorear y guiar su actividad en línea.
      </p>
      <p className="t-18 ">
        1xbetibet.com no recopila a sabiendas ninguna información de
        identificación personal de niños menores de 13 años. Si cree que su hijo
        proporcionó este tipo de información en nuestro sitio web, le
        recomendamos encarecidamente que se comunique con nosotros de inmediato
        y haremos nuestro mejor esfuerzo para eliminar rápidamente dicha
        información de nuestros registros.
      </p>
      <h3>Política de privacidad en línea únicamente</h3>
      <p className="t-18 ">
        Esta política de privacidad se aplica únicamente a nuestras actividades
        en línea y es válida para los visitantes de nuestro sitio web con
        respecto a la información que compartieron y/o recopilaron en
        1xbetibet.com. Esta política no se aplica a ninguna información
        recopilada fuera de línea o a través de canales distintos a este sitio
        web.
      </p>
      <h3>Consentimiento</h3>
      <p className="t-18 ">
        Al utilizar nuestro sitio web, usted acepta nuestra política de
        privacidad y acepta sus términos y condiciones.
      </p>
    </main>
  );
};

export default Privacy;
