import React from "react";

const Cookies = () => {
  return (
    <main className="privacy">
      <h3>Política de cookies para 1xbetibet.com</h3>
      <p className="t-18 ">
        Esta es la política de cookies para 1xbetibet.com, accesible desde
        1xbetibet.com.
      </p>
      <h3>Qué son las cookies</h3>
      <p className="t-18 ">
        Como es común en casi todos los sitios web profesionales, este sitio
        utiliza cookies, que son pequeños archivos que se descargan en su
        computadora para mejorar su experiencia. Esta página describe qué
        información recopilan, cómo la usamos y por qué a veces necesitamos
        almacenar estas cookies. También explicaremos cómo puede evitar que se
        almacenen estas cookies, aunque esto puede degradar o "romper" ciertos
        elementos de la funcionalidad del sitio.
      </p>
      <h3>Cómo usamos las cookies</h3>
      <p className="t-18 ">
        Utilizamos cookies por una variedad de razones que se detallan a
        continuación. Desafortunadamente, en la mayoría de los casos, no hay
        opciones estándar en la industria para desactivar las cookies sin
        desactivar completamente la funcionalidad y las características que
        agregan a este sitio. Se recomienda dejar activadas todas las cookies si
        no está seguro de si las necesita o no, en caso de que se utilicen para
        proporcionar un servicio que utiliza.
      </p>
      <h3>Desactivación de cookies</h3>
      <p className="t-18 ">
        Puede evitar la configuración de cookies ajustando la configuración de
        su navegador (consulte la Ayuda de su navegador para saber cómo
        hacerlo). Tenga en cuenta que desactivar las cookies afectará la
        funcionalidad de este y muchos otros sitios web que visite. La
        desactivación de cookies generalmente resultará en la desactivación de
        ciertas funciones y características de este sitio. Por lo tanto, se
        recomienda no desactivar las cookies.
      </p>
      <h3>Las cookies que establecemos</h3>
      <p className="t-18 ">
        Cookies relacionadas con la configuración del sitio. Para asegurar que
        tenga una gran experiencia en este sitio, ofrecemos la funcionalidad de
        establecer sus preferencias sobre cómo se ejecuta este sitio cuando lo
        utiliza. Para recordar sus preferencias, necesitamos establecer cookies
        para que esta información pueda ser llamada cada vez que interactúe con
        una página que se vea afectada por sus preferencias.
      </p>
      <h3>Cookies de terceros</h3>
      <p className="t-18 ">
        En algunos casos especiales, también utilizamos cookies proporcionadas
        por terceros de confianza. La siguiente sección detalla qué cookies de
        terceros puede encontrar a través de este sitio.
      </p>
      <p className="t-18 ">
        Este sitio utiliza Google Analytics, una de las soluciones de análisis
        más extendidas y de confianza en la web para ayudarnos a entender cómo
        utiliza el sitio y cómo podemos mejorar su experiencia. Estas cookies
        pueden rastrear cosas como cuánto tiempo pasa en el sitio y las páginas
        que visita, para que podamos seguir produciendo contenido atractivo.
      </p>
      <p className="t-18 ">
        Para más información sobre las cookies de Google Analytics, consulte la
        página oficial de Google Analytics.
      </p>
      <h3>Más información</h3>
      <p className="t-18 ">
        Esperamos que esto haya aclarado las cosas para usted y, como se
        mencionó anteriormente, generalmente es más seguro dejar las cookies
        habilitadas en caso de que interactúen con alguna de las funciones que
        utiliza en nuestro sitio.
      </p>
    </main>
  );
};

export default Cookies;
