import React from "react";
import "./AboutUs.css";
import aboutImgOne from "../../images/about-volleyball-male.webp";
import aboutImgTwo from "../../images/about-volleyball-female.webp";

const AboutUs = () => {
  return (
    <section className="about-us">
      <h2 className="section__header">Quiénes somos</h2>
      <div className="about__info-section">
        <div className="info about__info-one">
          <img
            src={aboutImgOne}
            alt="Un hombre juega voleibol"
            title="Un hombre juega voleibol"
            width="470"
            height="238"
            className="about__img-one"
          />
          <p className="t-20">
            Nos esforzamos por ofrecerte las mejores reseñas de bares deportivos
            en Chile para que puedas tomar una decisión informada. Nuestro
            objetivo es ayudarte a encontrar el lugar perfecto para ver eventos
            deportivos y hacer apuestas.
          </p>
        </div>
        <div className="info about__info-two">
          <img
            src={aboutImgTwo}
            alt="Una mujer juega voleibol"
            title="Una mujer juega voleibol"
            width="470"
            height="238"
            className="about__img-two"
          />
          <p className="t-20">
            Valoramos la honestidad, la precisión y la objetividad en nuestras
            reseñas. Estamos seguros de que cada uno de nuestros visitantes
            merece solo el mejor servicio e información. ¡Únete a nuestra
            comunidad y disfruta del deporte con nosotros!
          </p>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
