import { Link, useNavigate } from "react-router-dom";
import "./Header.css";
import burgerMenu from "../../images/burger-menu.svg";
import closeBtn from "../../images/close-btn.svg";
import { useState } from "react";
import inst from "../../images/inst-black.svg";
import telegram from "../../images/telegram-black.svg";

function Header() {
  const [isActive, setIsActive] = useState(false);
  const navigation = useNavigate();

  const handleLinkClick = () => {
    setIsActive(false);
  };

  const handleNavigation = (e, path, hash) => {
    e.preventDefault();
    navigation(path);
    if (hash) {
      setTimeout(() => {
        const element = document.getElementById(hash);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      });
    }
  };

  return (
    <header className="header__container">
      <div className="header__link-container">
        <Link to="/" className="header__link t-20">
          Página de inicio
        </Link>
        <Link to="/about-us" className="header__link t-20">
          Quiénes somos
        </Link>
        <Link to="/best-bars" className="header__link t-20">
          Barras superiores
        </Link>
        <Link
          to="/#contacts"
          className="header__link t-20"
          onClick={(e) => {
            handleNavigation(e, "/", "contacts");
          }}
        >
          Contactos
        </Link>
      </div>

      <div className="burger-menu">
        <img
          src={burgerMenu}
          alt="burger menu icon"
          className="burger__icon"
          onClick={() => setIsActive(true)}
        />
      </div>
      <div className="header__social">
        <a href="#">
          <img src={inst} alt="instagram icon" width="32" height="32"/>
        </a>
        <a href="#">
          <img src={telegram} alt="telegram icon" width="32" height="32"/>
        </a>
      </div>
      <nav className={`burger-menu__nav ${isActive ? "active" : ""}`} id="nav">
        <img
          src={closeBtn}
          className="burger__close-btn"
          id="close-icon"
          alt="close menu icon"
          onClick={() => setIsActive(false)}
        />
        <ul className="burger-menu__nav-container">
          <li className="burger-menu__nav-link">
            <Link
              to="/"
              className="header__link t-20"
              onClick={handleLinkClick}
            >
              Página de inicio
            </Link>
          </li>
          <li className="burger-menu__nav-link">
            <Link
              to="/about-us"
              className="header__link t-20"
              onClick={handleLinkClick}
            >
              Quiénes somos
            </Link>
          </li>
          <li className="burger-menu__nav-link">
            <Link
              to="/best-bars"
              className="header__link t-20"
              onClick={handleLinkClick}
            >
              Barras superiores
            </Link>
          </li>
        </ul>
      </nav>
    </header>
  );
}

export default Header;
