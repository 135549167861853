import React, { useState, useEffect } from "react";

const GoogleMap = ({link, title}) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {windowWidth >= 749 && (
        <iframe
          src={link}
          width="600"
          height="217"
          allowFullScreen=""
          title={title}
          referrerPolicy="no-referrer-when-downgrade"
          className="bar__map"
        ></iframe>
      )}
    </>
  );
};

export default GoogleMap;
