import React, { useState } from "react";
import "./Bars.css";
import SwiperDeals from "../Swiper/Swiper";
import GoogleMap from "../GoogleMap/GoogleMap";
import barOneImgOne from "../../images/zapping-lounge-img-1.webp";
import barOneImgTwo from "../../images/zapping-lounge-img-2.webp";
import barTwoImgOne from "../../images/cervecería-artesanal-anfiteatro-img-1.webp";
import barTwoImgTwo from "../../images/cervecería-artesanal-anfiteatro-img-2.webp";
import barThreeImgOne from "../../images/cirus-bar-img-1.webp";
import barThreeImgTwo from "../../images/cirus-bar-img-2.webp";
import barFourImgOne from "../../images/magnolia-bar-img-1.webp";
import barFourImgTwo from "../../images/magnolia-bar-img-2.webp";

import fiveStars from "../../images/5-stars.svg";
import fourStars from "../../images/4-stars.svg";

const Bars = () => {
  const [isActiveBarOne, setIsActiveBarOne] = useState(false);
  const [isActiveBarTwo, setIsActiveBarTwo] = useState(false);
  const [isActiveBarThree, setIsActiveBarThree] = useState(false);
  const [isActiveBarFour, setIsActiveBarFour] = useState(false);

  const zappingLounge =
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3330.5325629263784!2d-70.57343332276044!3d-33.40935727340577!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9662cede4df53e49%3A0xb358ca18be721b1b!2sAv.%20Apoquindo%205680%2C%207560943%20Las%20Condes%2C%20Regi%C3%B3n%20Metropolitana%2C%20Chile!5e0!3m2!1sen!2spl!4v1721332908227!5m2!1sen!2spl";
  const cerveceríaArtesanal =
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3329.778630504627!2d-70.62355162275988!3d-33.42901527339813!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9662cf62a2cb75c3%3A0x6c84dd8e2bc13a00!2sAv.%20Providencia%201234%2C%207500571%20Providencia%2C%20Santiago%2C%20Regi%C3%B3n%20Metropolitana%2C%20Chile!5e0!3m2!1sen!2spl!4v1721333551490!5m2!1sen!2spl";
  const cirusBar =
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3329.4634528502033!2d-70.61885502275956!3d-33.437230173394816!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9662cf7ed3e62395%3A0xe2b11fa7ccca5d42!2sManuel%20Montt%20999%2C%20Providencia%2C%20Regi%C3%B3n%20Metropolitana%2C%20Chile!5e0!3m2!1sen!2spl!4v1721333904813!5m2!1sen!2spl";
  const magnoliaBar =
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3330.851353214485!2d-70.60043782276053!3d-33.40104207340927!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9662cf49d9977e23%3A0x74e71a182235e3ab!2sAv.%20Nueva%20Costanera%203743%2C%20Vitacura%2C%20Regi%C3%B3n%20Metropolitana%2C%20Chile!5e0!3m2!1sen!2spl!4v1721334197707!5m2!1sen!2spl";

  return (
    <section className="bars">
      <h2 className="section__header bars__header">Barras superiores</h2>
      <ul className="bars__list">
        <li className="bar__item">
          <div className="bar__body">
            <div className="bar__img-container pc">
              <div className="bar__interier">
                <img
                  src={barOneImgOne}
                  alt="Zapping Lounge"
                  title="Zapping Lounge"
                  width="293"
                  height="217"
                  className="bar__img"
                />
                <img
                  src={barOneImgTwo}
                  alt="Zapping Lounge"
                  title="Zapping Lounge"
                  width="293"
                  height="217"
                  className="bar__img"
                />
              </div>
              <div
                className={`bar__map-container ${!isActiveBarOne && "hidden"}`}
              >
                <GoogleMap link={zappingLounge} title={"Zapping Lounge map"} />
              </div>
            </div>
            <div className="bar__info-container">
              <h3 className="bar__header">Zapping Lounge</h3>
              <p className="t-16 bar__adress">
                Av. Apoquindo 5680,
                <br></br>Las Condes, Santiago, Chile
              </p>
              <img
                src={fiveStars}
                alt="five star rating"
                className="bar__rating"
                width="124"
                height="25"
              />

              <ul className="bar__info">
                <li className="bar__info-item">
                  Espacio amplio con numerosas pantallas
                </li>
                <li className="bar__info-item">
                  Variedad de bebidas y aperitivos
                </li>
                <li className="bar__info-item">
                  Ubicación conveniente en el centro de la ciudad
                </li>
                <li className="bar__info-item">
                  Posibilidad de realizar apuestas en varios eventos deportivos
                </li>
              </ul>
              <SwiperDeals
                img1={barOneImgOne}
                img2={barOneImgTwo}
                barName="Zapping Lounge"
              />
              <p
                className={`bar__more-info t-18 ${!isActiveBarOne && "hidden"}`}
              >
                Zapping Lounge es un verdadero paraíso para los amantes del
                deporte y las apuestas. Aquí encontrarás una gran cantidad de
                pantallas que transmiten una amplia variedad de eventos
                deportivos en vivo. El bar ofrece una amplia selección de
                bebidas, incluidas cervezas artesanales y cócteles de autor. Su
                ubicación conveniente en el centro de Santiago lo convierte en
                el lugar ideal para reunirse con amigos y colegas. El interior
                de Zapping Lounge está decorado en un estilo moderno, creando un
                ambiente acogedor y confortable para todos los visitantes.
              </p>
            </div>
          </div>
          <div className="button__container">
            <button
              className="bar__open-btn btn"
              onClick={() => setIsActiveBarOne(!isActiveBarOne)}
            >
              {isActiveBarOne ? "Colapso" : "Despliegue"}
            </button>
            <a
              href="https://www.tripadvisor.ru/Attraction_Review-g303670-d4815526-Reviews-Zapping_Lounge-Curico_Maule_Region.html"
              className={`btn bar__reserve-btn ${!isActiveBarOne && "hidden"}`}
              target="_blank"
              rel="noreferrer"
            >
              Reserve
            </a>
          </div>
        </li>

        <li className="bar__item">
          <div className="bar__body">
            <div className="bar__img-container pc">
              <div className="bar__interier">
                <img
                  src={barTwoImgOne}
                  alt="Cervecería Artesanal Anfiteatro"
                  title="Cervecería Artesanal Anfiteatro"
                  width="293"
                  height="217"
                  className="bar__img"
                />
                <img
                  src={barTwoImgTwo}
                  alt="Cervecería Artesanal Anfiteatro"
                  title="Cervecería Artesanal Anfiteatro"
                  width="293"
                  height="217"
                  className="bar__img"
                />
              </div>
              <div
                className={`bar__map-container ${!isActiveBarTwo && "hidden"}`}
              >
                <GoogleMap
                  link={cerveceríaArtesanal}
                  title={"Cervecería Artesanal Anfiteatro map"}
                />
              </div>
            </div>
            <div className="bar__info-container">
              <h3 className="bar__header">Cervecería Artesanal Anfiteatro</h3>
              <p className="t-16 bar__adress">
                Av. Providencia 1234, Providencia, <br></br>Santiago, Chile
              </p>
              <img
                src={fourStars}
                alt="four star rating"
                className="bar__rating"
                width="99"
                height="25"
              />

              <ul className="bar__info">
                <li className="bar__info-item">
                  Amplia selección de cervezas artesanales de producción propia
                </li>
                <li className="bar__info-item">
                  Catas y festivales de cerveza regulares
                </li>
                <li className="bar__info-item">
                  Ambiente agradable y personal amigable
                </li>
                <li className="bar__info-item">
                  Posibilidad de realizar apuestas deportivas
                </li>
              </ul>
              <SwiperDeals
                img1={barTwoImgOne}
                img2={barTwoImgTwo}
                barName="Cervecería Artesanal Anfiteatro"
              />
              <p
                className={`bar__more-info t-18 ${!isActiveBarTwo && "hidden"}`}
              >
                Cervecería Artesanal Anfiteatro es un lugar único para los
                amantes de la cerveza artesanal y las apuestas deportivas. El
                bar ofrece cervezas propias, elaboradas con amor y siguiendo
                recetas tradicionales. Aquí se realizan catas y festivales de
                manera regular, haciendo que la visita al bar sea aún más
                interesante y entretenida. El interior de Cervecería Artesanal
                Anfiteatro está diseñado en estilo loft, creando un ambiente
                acogedor y relajado. Aquí puedes apostar por tu equipo favorito
                y disfrutar de eventos deportivos en las grandes pantallas.
              </p>
            </div>
          </div>
          <div className="button__container">
            <button
              className="bar__open-btn btn"
              onClick={() => setIsActiveBarTwo(!isActiveBarTwo)}
            >
              {isActiveBarTwo ? "Colapso" : "Despliegue"}
            </button>
            <a
              href="https://www.tripadvisor.ru/Attraction_Review-g294306-d6868834-Reviews-Cerveceria_Artesanal_Anfiteatro-Valparaiso_Valparaiso_Region.html"
              className={`btn bar__reserve-btn ${!isActiveBarTwo && "hidden"}`}
              target="_blank"
              rel="noreferrer"
            >
              Reserve
            </a>
          </div>
        </li>

        <li className="bar__item">
          <div className="bar__body">
            <div className="bar__img-container pc">
              <div className="bar__interier">
                <img
                  src={barThreeImgOne}
                  alt="Cirus Bar"
                  title="Cirus Bar"
                  width="293"
                  height="217"
                  className="bar__img"
                />
                <img
                  src={barThreeImgTwo}
                  alt="Cirus Bar"
                  title="Cirus Bar"
                  width="293"
                  height="217"
                  className="bar__img"
                />
              </div>
              <div
                className={`bar__map-container ${
                  !isActiveBarThree && "hidden"
                }`}
              >
                <GoogleMap link={cirusBar} title={"Cirus Bar map"} />
              </div>
            </div>
            <div className="bar__info-container">
              <h3 className="bar__header">Cirus Bar</h3>
              <p className="t-16 bar__adress">
                Av. Manuel Montt 999, Providencia, <br></br>Santiago, Chile
              </p>
              <img
                src={fourStars}
                alt="four star rating"
                className="bar__rating"
                width="99"
                height="25"
              />

              <ul className="bar__info">
                <li className="bar__info-item">Temática única de circo</li>
                <li className="bar__info-item">
                  Gran cantidad de transmisiones deportivas
                </li>
                <li className="bar__info-item">
                  Cócteles y aperitivos especiales
                </li>
                <li className="bar__info-item">Ubicación conveniente</li>
              </ul>
              <SwiperDeals
                img1={barThreeImgOne}
                img2={barThreeImgTwo}
                barName="Cirus Bar"
              />
              <p
                className={`bar__more-info t-18 ${
                  !isActiveBarThree && "hidden"
                }`}
              >
                Cirus Bar es un lugar donde el deporte se encuentra con el arte.
                El interior del bar está decorado con una temática de circo, lo
                que lo hace inusual e inolvidable. Aquí encontrarás numerosas
                pantallas que transmiten los eventos deportivos más importantes.
                El bar ofrece un menú especial con cócteles únicos y aperitivos
                deliciosos. Su ubicación conveniente y el personal amable hacen
                de Cirus Bar una excelente opción para todos los amantes del
                deporte y el buen ambiente.
              </p>
            </div>
          </div>
          <div className="button__container">
            <button
              className="bar__open-btn btn"
              onClick={() => setIsActiveBarThree(!isActiveBarThree)}
            >
              {isActiveBarThree ? "Colapso" : "Despliegue"}
            </button>
            <a
              href="https://www.tripadvisor.ru/Attraction_Review-g294298-d3841050-Reviews-Cirus_Bar-Puerto_Montt_Los_Lagos_Region.html"
              className={`btn bar__reserve-btn ${
                !isActiveBarThree && "hidden"
              }`}
              target="_blank"
              rel="noreferrer"
            >
              Reserve
            </a>
          </div>
        </li>

        <li className="bar__item">
          <div className="bar__body">
            <div className="bar__img-container pc">
              <div className="bar__interier">
                <img
                  src={barFourImgOne}
                  alt="Magnolia Bar"
                  title="Magnolia Bar"
                  width="293"
                  height="217"
                  className="bar__img"
                />
                <img
                  src={barFourImgTwo}
                  alt="Magnolia Bar"
                  title="Magnolia Bar"
                  width="293"
                  height="217"
                  className="bar__img"
                />
              </div>
              <div
                className={`bar__map-container ${!isActiveBarFour && "hidden"}`}
              >
                <GoogleMap link={magnoliaBar} title={"Magnolia Bar map"} />
              </div>
            </div>
            <div className="bar__info-container">
              <h3 className="bar__header">Magnolia Bar</h3>
              <p className="t-16 bar__adress">
                Av. Nueva Costanera 3743, Vitacura, <br></br>Santiago, Chile
              </p>
              <img
                src={fourStars}
                alt="four star rating"
                className="bar__rating"
                width="99"
                height="25"
              />

              <ul className="bar__info">
                <li className="bar__info-item">
                  Interior elegante y ambiente acogedor
                </li>
                <li className="bar__info-item">
                  Crupieres profesionales para apuestas deportivas
                </li>
                <li className="bar__info-item">Servicio de alta calidad</li>
                <li className="bar__info-item">
                  Gran variedad de bebidas y platos
                </li>
              </ul>
              <SwiperDeals
                img1={barFourImgOne}
                img2={barFourImgTwo}
                barName="Magnolia Bar"
              />
              <p
                className={`bar__more-info t-18 ${
                  !isActiveBarFour && "hidden"
                }`}
              >
                Magnolia Bar es un lugar donde todos encuentran algo a su gusto.
                El interior elegante y el ambiente acogedor hacen del bar el
                lugar perfecto para pasar la noche con amigos. Aquí trabajan
                crupieres profesionales que te ayudarán a hacer tus apuestas en
                tus eventos deportivos favoritos. El bar ofrece una amplia
                variedad de bebidas, desde cócteles clásicos hasta exclusivos de
                autor, así como una variedad de platos que satisfarán a
                cualquier gourmet. Magnolia Bar es una combinación de estilo,
                comodidad y emoción.
              </p>
            </div>
          </div>
          <div className="button__container">
            <button
              className="bar__open-btn btn"
              onClick={() => setIsActiveBarFour(!isActiveBarFour)}
            >
              {isActiveBarFour ? "Colapso" : "Despliegue"}
            </button>
            <a
              href="https://www.tripadvisor.ru/Attraction_Review-g317797-d6827616-Reviews-Magnolia_Bar-Chillan_Nuble_Region.html"
              className={`btn bar__reserve-btn ${!isActiveBarFour && "hidden"}`}
              target="_blank"
              rel="noreferrer"
            >
              Reserve
            </a>
          </div>
        </li>
      </ul>
    </section>
  );
};

export default Bars;
