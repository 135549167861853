import React from "react";
import "./Main.css";
import AboutUs from "../AboutUs/AboutUs";
import Bars from "../Bars/Bars";
import Popup from "../Popup/Popup";
import mainCover from "../../images/main-img.webp";
import mainCoverMob from "../../images/main-img-mob.webp";

const MainCover = () => {
  return (
    <main className="main">
      {/* <Popup/> */}
      <section className="cover__content">
        <img
          className="cover__img"
          src={mainCover}
          alt="las personas juegan voleibol"
          title="las personas juegan voleibol"
        />
        <img
          className="cover__img-mob"
          src={mainCoverMob}
          alt="las personas juegan voleibol"
          width="375"
          height="568"
          title="las personas juegan voleibol"
        />
        <div className="cover__overlay">
          <h1 className="cover__header">
            ¡Bienvenidos a nuestro sitio web dedicado a las reseñas de los
            mejores bares deportivos de Chile!
          </h1>
          <p className="t-24 cover__paragraph">
            Nuestro objetivo es brindarte la información más actualizada y
            precisa sobre los bares deportivos donde no solo puedes disfrutar de
            tus eventos deportivos favoritos, sino también apostar en ellos. Nos
            esforzamos por crear una comunidad de personas con ideas afines que
            valoran el ocio de calidad, el ambiente excelente y la emoción de
            las apuestas deportivas. Nos enorgullecemos de ofrecer reseñas
            objetivas y detalladas para ayudarte a elegir el lugar perfecto para
            relajarte y entretenerte. Nuestros principales valores son la
            honestidad, la precisión y la atención a los detalles. Estamos
            seguros de que con nuestra ayuda, encontrarás el mejor bar deportivo
            que cumpla con tus expectativas. ¡Únete a nosotros y descubre los
            mejores bares deportivos de Chile!
          </p>
        </div>
      </section>
      <AboutUs />
      <Bars />
    </main>
  );
};

export default MainCover;
